import React, { useContext } from 'react'
import { graphql, PageProps } from 'gatsby'
import {
  PartnersPageQuery,
  Strapi__Component_Meta_Metadata,
  Strapi_Global,
  Strapi__Component_Partners_Advantage,
  Strapi__Component_Partners_Step,
  Strapi__Component_Links_Button,
  Strapi__Component_Partners_Avatar,
} from 'gatsby-graphql'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useLocalizePage } from '@/utils/hooks'
import { SectionDivider } from '@/components/elements/section-divider'
import Button from '@/components/elements/button'
import AdvantagesSection from '@/components/sections/partners/advantages'
import ThemeTypeContext from '@/components/themeTypeContext'
import { IPageContext } from '@/types/pages'
import { theme } from 'src/theme/ThemeProvider'
import NetworksSection from '@/components/sections/partners/networks'

const Partners: React.FC<PageProps<PartnersPageQuery>> = (props) => {
  const { setTheme } = useContext(ThemeTypeContext)
  setTheme('core')

  const data = props?.data
  const pageContext = props?.pageContext as IPageContext
  const location = props?.location

  const global = data?.strapiGlobal as Strapi_Global
  const strapiPartners = data?.strapiPartners
  const metaData = strapiPartners?.MetaData as Strapi__Component_Meta_Metadata
  const advantages = strapiPartners?.Advantage as Strapi__Component_Partners_Advantage[]
  const steps = strapiPartners?.Step as Strapi__Component_Partners_Step[]
  const partnerButton = strapiPartners?.PartnerButton as Strapi__Component_Links_Button
  const avatars = strapiPartners?.Network?.Avatar as Strapi__Component_Partners_Avatar[]

  useLocalizePage(pageContext, location)
  const isMobile = useMediaQuery('(max-width: 925px)')
  return (
    <>
      <SEO metaData={metaData} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, ...metaData }} theme={theme}>
        <Box sx={{ textAlign: 'center', marginBottom: '60px', width: '100%' }}>
          <Box sx={{ p: { sm: 4 }, width: { sm: 'auto', xs: '90%' }, margin: 'auto' }}>
            <Typography sx={{ fontSize: { sm: '20px', xs: '18px' }, width: { xs: '100%', md: '66%' }, margin: 'auto' }}>
              {strapiPartners?.Subtitle}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '30px', md: '40px' },
                fontWeight: 'bold',
                textAlign: 'center',
                mt: { sm: 0, xs: 3 },
              }}
            >
              {strapiPartners?.Title}
            </Typography>
            <Typography
              sx={{ fontSize: { sm: '20px', xs: '18px' }, width: { xs: '100%', md: '66%' }, margin: 'auto', mt: 3.2 }}
            >
              {strapiPartners?.Caption}
            </Typography>
          </Box>

          <SectionDivider />
          <NetworksSection title={strapiPartners?.Network.Title} avatars={avatars} />
          <SectionDivider />
          <AdvantagesSection title={strapiPartners?.HowItWorksTitle} advantages={advantages} steps={steps} />
          <Box sx={{ width: { xs: '100%', md: '336px' }, margin: 'auto', pb: 8, pt: 4 }}>
            <Button button={partnerButton} centered />
          </Box>
          {!isMobile && <SectionDivider />}
        </Box>
      </Layout>
    </>
  )
}

export default Partners

export const query = graphql`
  query PartnersPage($locale: String) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiPartners(locale: { eq: $locale }) {
      MetaData {
        MetaTitle
        MetaDescription
      }
      Subtitle
      Title
      Caption
      Network {
        Title
        Avatar {
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
              publicURL
            }
          }
          Url
          isHyperLink
          NewTab
        }
      }
      HowItWorksTitle
      Step {
        Number
        Text {
          data {
            Text
          }
        }
      }
      Advantage {
        Title
        Paragraph {
          data {
            Paragraph
          }
        }
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
        }
      }
      PartnerButton {
        newTab
        text
        type
        url
        icon
      }
    }
  }
`
